<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <template v-if="internetConnection">
            <LoaderPage v-if="isLoading" />
            <template v-else>
                <div class="uk-margin">
                    <div uk-grid class="uk-grid-small">
                        <div class="uk-form-controls uk-width-small">
                            <select
                                class="uk-select"
                                id="form-horizontal-select"
                                v-model="filterType"
                            >
                                <option :value="null">Select Filter</option>
                                <option :value="'name'">Admin Name</option>
                                <option :value="'phone_number'">
                                    Phone Number
                                </option>
                            </select>
                        </div>
                        <div class="uk-width-medium">
                            <div class="uk-inline">
                                <span
                                    class="uk-form-icon uk-form-icon-flip"
                                    uk-icon="icon: search"
                                ></span>
                                <input
                                    :disabled="filterType === null"
                                    v-model="query"
                                    class="uk-input"
                                    @keyup.enter="onQueryEnter"
                                    type="text"
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                        <div class="uk-inline">
                            <button
                                style="
                                    width: 100px;
                                    cursor: pointer;
                                    height: 40px;
                                "
                                class="uk-button-primary"
                                @click="showModalCreateAdminJob"
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </div>

                <!-- list -->
                <div class="uk-margin-top uk-card uk-card-default">
                    <div class="uk-overflow-auto">
                        <table
                            class="uk-table uk-table-striped uk-table-middle"
                        >
                            <thead>
                                <tr>
                                    <th class="uk-table-shrink">No</th>
                                    <th class="uk-text-wrap uk-text-center">
                                        Admin's Name
                                    </th>
                                    <th class="uk-text-wrap uk-text-center">
                                        Business Phone Number <br />(Linked to
                                        WhatsApp)
                                    </th>
                                    <th
                                        class="uk-text-wrap uk-text-center uk-width-small"
                                    >
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <LoadingTable v-if="tableLoading" :colspan="4" />
                            <template>
                                <tbody v-if="adminJobVacancy.docs.length > 0">
                                    <tr
                                        v-for="(
                                            admin, index
                                        ) in adminJobVacancy.docs"
                                        :key="index"
                                    >
                                        <td class="uk-table-shrink">
                                            {{
                                                autoIncrementNumberPagination({
                                                    pagingCounter:
                                                        adminJobVacancy.pagingCounter,
                                                    index,
                                                })
                                            }}
                                        </td>
                                        <td class="uk-text-wrap uk-text-center">
                                            {{ admin.name || "-" }}
                                        </td>
                                        <td class="uk-text-wrap uk-text-center">
                                            {{ admin.phone_number || "-" }}
                                        </td>
                                        <td
                                            class="uk-text-nowrap uk-text-center uk-flex uk-flex-column"
                                            style="align-items: center"
                                        >
                                            <button
                                                style="
                                                    width: 100px;
                                                    cursor: pointer;
                                                    margin-bottom: 5px;
                                                    height: 30px;
                                                    background: #333747;
                                                "
                                                class="uk-button-primary"
                                                v-clipboard:copy="
                                                    (admin && admin._id) || null
                                                "
                                                v-clipboard:success="onCopy"
                                            >
                                                Copy ID
                                            </button>
                                            <button
                                                class="uk-button-default uk-margin-small-top"
                                                style="
                                                    width: 100px;
                                                    cursor: pointer;
                                                    height: 30px;
                                                "
                                                @click="
                                                    editAdminJob({
                                                        data: admin,
                                                    })
                                                "
                                            >
                                                Edit
                                            </button>
                                            <button
                                                class="uk-button-danger uk-margin-small-top"
                                                style="
                                                    width: 100px;
                                                    cursor: pointer;
                                                    height: 30px;
                                                "
                                                @click="
                                                    deleteAdminJob({
                                                        data: admin,
                                                    })
                                                "
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <empty-table v-else :colspan="4" />
                            </template>
                        </table>
                    </div>
                    <pagination
                        :total-data="adminJobVacancy.totalDocs"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                    />
                </div>
                <!-- end list -->
            </template>
            <!-- Create or Edit Modal -->
            <div
                id="create-or-edit-admin-job-modal"
                class="create-or-edit-admin-job-modal uk-flex-top"
                uk-modal
                esc-close="false"
                bg-close="false"
            >
                <div class="uk-modal-dialog">
                    <button
                        class="uk-modal-close-default"
                        type="button"
                        uk-close
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">
                            {{
                                mode === "create"
                                    ? "Create Admin Job Vacancy"
                                    : "Edit Admin Job Vacancy"
                            }}
                        </h2>
                    </div>
                    <form
                        class="uk-form-stacked"
                        @submit.prevent="createOrEdit"
                    >
                        <div class="uk-modal-body">
                            <div class="uk-margin">
                                <label for="adminName" class="uk-form-label"
                                    >Admin Name's
                                    <span class="uk-text-danger">*</span></label
                                >
                                <div class="uk-form-controls">
                                    <input
                                        id="adminName"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        v-model="selectedAdminJob.name"
                                        :maxlength="30"
                                        autofocus
                                    />
                                    <div class="uk-flex uk-flex-between">
                                        <span
                                            class="uk-text-small uk-text-danger"
                                            >{{
                                                errorMessages.name || null
                                            }}</span
                                        >
                                        <span class="uk-text-small"
                                            >{{
                                                selectedAdminJob.name.length
                                            }}/30 Characters</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="uk-margin">
                                <label for="phone_number" class="uk-form-label"
                                    >Business Phone Number (Linked to WhatsApp)
                                    <span class="uk-text-danger">*</span></label
                                >
                                <div class="uk-form-controls">
                                    <input
                                        id="phone_number"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        v-model="selectedAdminJob.phone_number"
                                        autofocus
                                    />

                                    <span
                                        v-if="errorMessages.phone_number"
                                        class="uk-text-small uk-text-danger"
                                        >{{ errorMessages.phone_number }}</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-text-right">
                            <button
                                class="uk-button uk-button-default uk-modal-close button-blue-transparent uk-margin-small-right"
                                type="button"
                            >
                                Cancel
                            </button>
                            <button
                                v-if="createOrEditSaveButtonLoading"
                                type="button"
                                class="uk-button uk-button-default uk-border-rounded"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left"
                                    >Loading</span
                                >
                            </button>
                            <button
                                v-else
                                type="submit"
                                class="uk-button"
                                :class="[
                                    disabledCreateOrEditSaveButton === true
                                        ? 'uk-button-default'
                                        : 'uk-button-primary',
                                ]"
                                :disabled="
                                    disabledCreateOrEditSaveButton === true
                                "
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End Modal -->

            <!-- Confirmation to Delete Modal -->
            <div
                id="confirmation-delete-modal-box"
                class="confirmation-delete-modal-box uk-flex-top"
                uk-modal
                esc-close="false"
                bg-close="false"
            >
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        class="uk-modal-close-default"
                        type="button"
                        @click="resetSelectedAdminJobData"
                        uk-close
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">
                            Are you sure to delete this data ?
                        </h2>
                    </div>
                    <div class="uk-modal-body">
                        <p>Admin Name's : {{ selectedAdminJob.name || "-" }}</p>
                        <p>
                            Phone Number :
                            {{ selectedAdminJob.phone_number || "-" }}
                        </p>
                    </div>
                    <div
                        class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right"
                    >
                        <button
                            v-if="deleteButtonLoading"
                            type="button"
                            class="uk-button uk-button-default uk-border-rounded"
                            disabled
                        >
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <button
                            v-else
                            type="submit"
                            class="uk-button uk-border-rounded"
                            :class="[
                                disabledDeleteButton === true
                                    ? 'uk-button uk-button-default'
                                    : 'uk-button uk-button-danger',
                            ]"
                            :disabled="disabledDeleteButton === true"
                            @click="doDeleteAdminJobVacancy"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
            <!-- End Modal -->
        </template>
        <NoInternetConnection v-else />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { notificationSuccess, notificationDanger } from "@/utils/notification";
import { getUserLogin } from "@/utils/auth";
import formatter from "@/utils/formatter";

export default {
    components: {
        Pagination: () => import("@/components/globals/Pagination"),
        EmptyTable: () => import("@/components/globals/tables/EmptyTable"),
        LoadingTable: () => import("@/components/globals/tables/Loading"),
        LoaderPage: () => import("@/components/globals/LoaderPage"),
        NoInternetConnection: () =>
            import("@/components/globals/NoInternetConnection"),
    },
    data() {
        return {
            user_cred: getUserLogin(),
            internetConnection: true,
            meta: {
                limit: 10,
                page: 1,
            },
            adminForm: {
                name: "",
                phone_number: "",
            },
            adminJobVacancy: {
                docs: [],
                totalDocs: 0,
                pagingCounter: 0,
            },
            selectedAdminJob: {
                id: null,
                name: "",
                phone_number: "",
            },
            tempSelectedAdminJob: {
                id: null,
                name: "",
                phone_number: "",
            },
            errorMessages: {
                name: "",
                phone_number: "",
            },
            query: "",
            filterType: null,
            isLoading: false,
            tableLoading: false,
            createOrEditSaveButtonLoading: false,
            deleteButtonLoading: false,
            mode: "create",
            phoneNumberRgxp: /^[+]?[0-9]*$/,
            adminNameRgxp: /^[a-zA-Z ]*$/,
        };
    },
    watch: {
        async meta() {
            try {
                if (!navigator.onLine) return (this.internetConnection = false);
                this.tableLoading = true;
                await this.setAdminJobData();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        "selectedAdminJob.phone_number"(val) {
            this.checkPhoneNumberError(val);
        },
        "selectedAdminJob.name"(val) {
            this.checkAdminNameError(val);
        },
    },
    computed: {
        disabledCreateOrEditSaveButton() {
            let disabled = true;
            if (this.mode === "create") {
                if (
                    this.selectedAdminJob.name === "" ||
                    this.selectedAdminJob.phone_number === "" ||
                    this.errorMessages.name !== "" ||
                    this.errorMessages.phone_number !== ""
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else if (this.mode === "edit") {
                if (
                    this.selectedAdminJob.name === "" ||
                    this.selectedAdminJob.phone_number === "" ||
                    this.errorMessages.name !== "" ||
                    this.errorMessages.phone_number !== "" ||
                    (this.removeSpaceFromString({
                        string: this.selectedAdminJob.name,
                    }).toLowerCase() ===
                        this.removeSpaceFromString({
                            string: this.tempSelectedAdminJob.name,
                        }).toLowerCase() &&
                        this.removeSpaceFromString({
                            string: this.selectedAdminJob.phone_number,
                        }).toLowerCase() ===
                            this.removeSpaceFromString({
                                string: this.tempSelectedAdminJob.phone_number,
                            }).toLowerCase())
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            }
            return disabled;
        },
        disabledDeleteButton() {
            let disabled = true;
            if (
                this.selectedAdminJob.id === null ||
                this.selectedAdminJob.name === "" ||
                this.selectedAdminJob.phone_number === ""
            ) {
                disabled = true;
            } else {
                disabled = false;
            }
            return disabled;
        },
    },
    beforeMount() {
        if (window.UIkit.modal(".create-or-edit-admin-job-modal"))
            window.UIkit.modal(".create-or-edit-admin-job-modal").$destroy(
                true
            );
    },
    mounted() {
        try {
            if (!navigator.onLine) return (this.internetConnection = false);
            this.isLoading = true;
            this.setAdminJobData();
            if (!this.consist(this.user_cred.roles, ['qwe123','superadmin-tech','superadmin-product','admin-operation'])) {
                this.$router.push('/');
            }
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            createAdminJob: "adminJobVacancyV4/createAdminJob",
            adminJobList: "adminJobVacancyV4/adminJobList",
            updateAdminJob: "adminJobVacancyV4/updateAdminJob",
            deleteAdminJobAPI: "adminJobVacancyV4/deleteAdminJob",
        }),
        async setAdminJobData() {
            try {
                if (!navigator.onLine) return (this.internetConnection = false);
                const response = await this.adminJobList(this.meta);
                if (
                    response &&
                    response.data &&
                    response.data.status === "OK" &&
                    response.data.result
                ) {
                    this.adminJobVacancy = response.data.result;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        resetMeta() {
            this.meta = {
                limit: 10,
                page: 1,
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value,
            };
        },
        onQueryEnter() {
            this.resetMeta();
            this.meta[this.filterType] = this.query;
            this.setAdminJobData();
        },
        autoIncrementNumberPagination({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({
                pagingCounter,
                index,
            });
        },
        changeLimit(e) {
            if (!navigator.onLine) return (this.internetConnection = false);
            this.meta = {
                ...this.meta,
                limit: e.target.value,
            };
        },
        changePage(value) {
            if (!navigator.onLine) return (this.internetConnection = false);
            this.meta = {
                ...this.meta,
                page: value,
            };
        },
        consist(user_roles, value) {
            return value.some((i) => user_roles.includes(i));
        },
        onCopy(e) {
            if (navigator.onLine) {
                // Check internet connection
                this.internetConnection = true;
                notificationSuccess("Copied to clipboard: " + e.text);
            } else {
                this.internetConnection = false;
            }
        },
        removeSpaceFromString({ string }) {
            return string.replace(/\s/g, "");
        },
        resetSelectedAdminJobData() {
            this.selectedAdminJob.id = null;
            this.selectedAdminJob.name = "";
            this.selectedAdminJob.phone_number = "";
        },
        fillSelectedAdminJobData({ id, name, phone_number }) {
            this.selectedAdminJob.id = id;
            this.selectedAdminJob.name = name;
            this.selectedAdminJob.phone_number = phone_number;
        },
        fillTempSelectedAdminJobData({ id, name, phone_number }) {
            this.tempSelectedAdminJob.id = id;
            this.tempSelectedAdminJob.name = name;
            this.tempSelectedAdminJob.phone_number = phone_number;
        },
        async showModalCreateAdminJob() {
            try {
                if (!navigator.onLine) return (this.internetConnection = false);
                this.resetSelectedAdminJobData();
                this.mode = "create";
                this.errorMessages.name = "";
                this.errorMessages.phone_number = "";
                await window.UIkit.modal(
                    ".create-or-edit-admin-job-modal"
                ).show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showModalDeleteAdminJob(data) {
            try {
                if (!navigator.onLine) return (this.internetConnection = false);
                this.resetSelectedAdminJobData();
                if (data) {
                    this.fillSelectedAdminJobData({
                        id: data._id,
                        name: data.name,
                        phone_number: data.phone_number,
                    });
                }
                await window.UIkit.modal(
                    "#confirmation-delete-modal-box"
                ).show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        editAdminJob({ data }) {
            this.showModalEditAdminJob(data);
        },
        deleteAdminJob({ data }) {
            this.showModalDeleteAdminJob(data);
        },
        async showModalEditAdminJob(data) {
            try {
                if (!navigator.onLine) return (this.internetConnection = false);
                this.resetSelectedAdminJobData();
                this.mode = "edit";
                this.errorMessages.name = "";
                this.errorMessages.phone_number = "";
                if (data) {
                    this.fillSelectedAdminJobData({
                        id: data._id,
                        name: data.name,
                        phone_number: data.phone_number,
                    });
                    this.fillTempSelectedAdminJobData({
                        id: data._id,
                        name: data.name,
                        phone_number: data.phone_number,
                    });
                }
                await window.UIkit.modal(
                    ".create-or-edit-admin-job-modal"
                ).show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async createOrEdit() {
            try {
                if (!navigator.onLine) return (this.internetConnection = false);
                if (this.mode === "create") {
                    await this.doCreateAdminJob();
                } else if (this.mode === "edit") {
                    await this.doUpdateAdminJob();
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doCreateAdminJob() {
            try {
                if (!navigator.onLine) return (this.internetConnection = false);
                this.createOrEditSaveButtonLoading = true;
                const payloads = {
                    name: this.selectedAdminJob.name,
                    phone_number: this.selectedAdminJob.phone_number,
                };
                const response = await this.createAdminJob(payloads);
                if (
                    response &&
                    response.data &&
                    response.data.status === "OK"
                ) {
                    this.resetSelectedAdminJobData();
                    await window.UIkit.modal(
                        ".create-or-edit-admin-job-modal"
                    ).hide();
                    this.tableLoading = true;
                    await this.setAdminJobData();
                    notificationSuccess(
                        "Admin Job Vacancy created successfully"
                    );
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.createOrEditSaveButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async doUpdateAdminJob() {
            try {
                if (!navigator.onLine) return (this.internetConnection = false);
                this.createOrEditSaveButtonLoading = true;
                const payloads = {
                    id: this.selectedAdminJob.id,
                    name: this.selectedAdminJob.name,
                    phone_number: this.selectedAdminJob.phone_number,
                };
                const response = await this.updateAdminJob(payloads);
                if (
                    response &&
                    response.data &&
                    response.data.status === "OK"
                ) {
                    this.resetSelectedAdminJobData();
                    await window.UIkit.modal(
                        ".create-or-edit-admin-job-modal"
                    ).hide();
                    this.tableLoading = true;
                    await this.setAdminJobData();
                    notificationSuccess(
                        "Admin Job Vacancy updated successfully"
                    );
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.createOrEditSaveButtonLoading = false;
                this.tableLoading = false;
            }
        },
        async doDeleteAdminJobVacancy() {
            try {
                if (!navigator.onLine) return (this.internetConnection = false);
                this.deleteButtonLoading = true;
                const response = await this.deleteAdminJobAPI(
                    this.selectedAdminJob.id
                );
                if (
                    response &&
                    response.data &&
                    response.data.status === "OK"
                ) {
                    this.resetSelectedAdminJobData();
                    await window.UIkit.modal(
                        ".confirmation-delete-modal-box"
                    ).hide();
                    this.tableLoading = true;
                    await this.setAdminJobData();
                    notificationSuccess(
                        "Admin Job Vacancy deleted successfully"
                    );
                }
            } catch (error) {
                await window.UIkit.modal(
                    ".confirmation-delete-modal-box"
                ).hide();
                notificationDanger(error);
            } finally {
                this.deleteButtonLoading = false;
                this.tableLoading = false;
            }
        },
        checkPhoneNumberError(value) {
            const valid = this.phoneNumberRgxp.test(value);
            if (value && this.mode === "edit") {
                this.errorMessages.phone_number = "";
            } else if (valid && value.length >= 11 && value.length <= 14) {
                this.errorMessages.phone_number = "";
            } else if (
                (valid && value.length >= 1 && value.length <= 14) ||
                value.length > 14
            ) {
                this.errorMessages.phone_number =
                    "Only accept 11-14 character numbers";
            } else if (!valid) {
                this.errorMessages.phone_number = "Incorrect characters format";
            } else {
                this.errorMessages.phone_number = "";
            }
        },
        checkAdminNameError(val) {
            const valid = this.adminNameRgxp.test(val);
            if (valid) {
                this.errorMessages.name = "";
            } else {
                this.errorMessages.name = "Admin Name's can only use letters";
            }
        },
    },
};
</script>

<style></style>
